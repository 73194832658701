export default {
  main: {
    title: 'Ultimative Funktionen in einer Anwendung',
    slogan: 'FEATURES',
  },
  item1: {
    header: 'Angebote',
    description: 'Spare bares Geld durch Angebote diverser Händler',
  },
  item2: {
    header: 'Einkaufslisten',
    description: 'Verwalte und teile deine personalisierte Einkaufslisten online und offline',
  },
  item3: {
    header: 'Mehrsprachig',
    description: 'In deutscher und englischer Sprache verfügbar',
  },
  item4: {
    header: 'Rückrufe',
    description: 'Erhalte Informationen zu aktuellen Lebensmittelrückrufen',
  },
  item5: {
    header: 'Rezepte',
    description: 'Wähle aus zahlreichen Rezepten deine Lieblingsgerichte',
  },
  item6: {
    header: 'Transparenz',
    description: 'Mit vollständiger Transparenz mehr über Ihr Produkt wissen',
  },
};

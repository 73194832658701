export default {
  navbar: {
    about: 'Über uns',
    contact: 'Kontakt',
  },
  banner: {
    heading: 'Die mobile App für deine effiziente Lebensmittelplanung',
    subHeading:
      'Stelle Dir aus einer Vielzahl an Rezepten deinen persönlichen Wochenplan zusammen, vergleiche Angebote und kaufe clever ein.',
    surveyText: 'Helfen Sie uns, diese Anwendung live zu bringen und den Welthunger zu bekämpfen',
    surveyButton: 'Anonyme Umfrage',
    coming: 'Wir werden demnächst im App Store live geschaltet!',
  },
  features: {
    title: 'Warum wählen Sie unsere App',
    slogan: 'FEATURES',
  },
  newsletter: {
    header: 'Verpasse keine unserer Neuigkeiten. Hier gehts zum Newsletter.',
    description:
      'Abonniere unseren Newsletter, um als einer der ersten von unseren Neuigkeiten zu erfahren und Zugriff auf die App zu erhalten.',
    title: 'Bleib informiert',
    button: 'Abonnieren',
    email: 'E-Mail Addresse',
    survey: 'Jetzt an unserer anonymen Umfrage teilnehmen und Yush! mitgestalten',
  },
  keypoints: {
    image: "require('../../assets/images/keyPoints-de.png')",
  },
  footer: {
    follow: 'Folge uns',
    terms: 'Nutzungsbedingungen',
    privacy: 'Datenschutzerklärung',
    work: 'Jetzt ein Teil von Yush! werden und App herunterladen',
    corporation: 'Arbeite mit uns',
    contact: 'Kontakt',
    upload: 'Rezept hochladen',
  },
};

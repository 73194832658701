export default {
  main: {
    id: 'Produkt-ID',
  },
  item1: {
    header: 'Produktion',
    description: 'Choose your favourite products from a wide range of offers',
  },
  item2: {
    header: 'Fertigung',
    description: 'Put together your personal shopping list and check the approximate amount of money you will spend',
  },
  item3: {
    header: 'Logistik',
    description: 'We help you to sort your shopping list so that you can do your shopping as fast as possible',
  },
  item4: {
    header: 'Supermärkte',
    description: 'We recommend healthy recipes based on your shopping',
  },
  item5: {
    header: 'Verbrauch',
    description: 'We recommend healthy recipes based on your shopping',
  },
};

export default {
  navbar: {
    about: 'About us',
    contact: 'Contact',
  },
  banner: {
    heading: 'The app for your efficient grocery planning',
    subHeading: 'Put together your personal weekly plan from a variety of recipes, compare offers and shop smart.',
    surveyText: 'Help us bring this application live and fight world hunger',
    surveyButton: 'Anonymous survey',
    coming: 'We are coming live on App Store soon!',
  },
  features: {
    title: 'Why you choose our app',
    slogan: 'KEY FEATURES',
  },
  newsletter: {
    header: "Don't miss our news. Click here for the newsletter.",
    description: 'Subscribe to our newsletter to be one of the first to hear about our news and get access to the app.',
    title: 'Stay informed',
    button: 'Subscribe',
    email: 'E-Mail address',
    survey: 'Take our anonymous survey and bring Yush! live soon',
  },
  keypoints: {
    image: '../../assets/images/keyPoints-en.png',
  },
  footer: {
    follow: 'Follow us',
    terms: 'Terms of use',
    privacy: 'Privacy policy',
    work: 'Become now a part of Yush! and download the app',
    corporation: 'Work with us',
    contact: 'Contact',
    upload: 'Upload recipes',
  },
};

export default {
  main: {
    title: 'Here´s How!',
    slogan: 'How it works?',
  },
  item1: {
    header: 'Select recipes',
    description: 'Whether vegetarian, vegan, breakfast or dessert, here you will find the right recipes',
    footer: 'Saving made easy',
  },
  item2: {
    header: 'Create weekly plan',
    description: 'Create your individual weekly plan',
    footer: 'Goodbye spontaneous buying',
  },
  item3: {
    header: 'Compare offers',
    description: 'Choose from a variety of offers and save week after week',
    footer: 'Finally time for the important things in life',
  },
  item4: {
    header: 'Shop smart',
    description:
      'Add ingredients you need for your weekly plan to your personal shopping list with only one click and share it with your family and friends',
    footer: 'No more thinking about it',
  },
};

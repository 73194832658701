import i18next from 'i18next';
// import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import resources from '../assets/i18n/translations';

i18next
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    cookieMinutes: 10,
    cookieDomain: 'myDomain',
    lng: 'de',
    // backend: {
    //   /* translation file path */
    //   loadPath: '../assets/i18n/{{ns}}/{{lng}}.json',
    // },
    fallbackLng: 'de',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    // ns: ['translations'],
    // defaultNS: 'translations',
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    resources,
    initImmediate: false,
    // react: {
    //   wait: true,
    // },
  });

i18next.loadLanguages(Object.keys(resources));
export default i18next;

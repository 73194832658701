export default {
  main: {
    title: 'Ultimate features in one application',
    slogan: 'Healthy, sustainable, time and money saving',
  },
  item1: {
    header: 'Healthy',
    description:
      'We provide you with healthy recipes and you determine which ones you want to include in your weekly plan. Healthy eating was never easier. And if one of your favorite dishes is missing, add it in just a few steps.',
  },
  item2: {
    header: 'Sustainable',
    description:
      'We are already part of the initiative „ZU GUT FÜR DIE TONNE“. You too can actively contribute to reducing food waste by using Yush!.',
  },
  item3: {
    header: 'Time saving',
    description:
      'In just a few minutes, you can put together your personal weekly plan from numerous recipes. The ingredients you need are directly added to your shopping list. No more long thoughts about what you want to eat.',
  },
  item4: {
    header: 'Money saving',
    description:
      'With the right planning, you only buy the food that you actually need. You can also compare offers from various retailers before you start shopping. Goodbye impulse buying! Hello planning!',
  },
};

export default {
  header: 'Bedingungen & Konditionen',
  para1:
    'Durch das Herunterladen oder die Nutzung der App gelten diese Bedingungen automatisch für Sie - \
  Sie sollten daher sicherstellen, dass Sie sie vor der Nutzung der App sorgfältig lesen. Es ist Ihnen nicht\
   gestattet, die App, einen Teil der App oder unsere Marken in irgendeiner Weise zu kopieren oder zu verändern.\
    Sie dürfen nicht versuchen, den Quellcode der Anwendung zu extrahieren, und Sie sollten auch nicht versuchen,\
     die Anwendung in andere Sprachen zu übersetzen oder davon abgeleitete Versionen zu erstellen. Die App selbst\
      und alle damit verbundenen Marken, Urheberrechte, Datenbankrechte und andere Rechte an geistigem Eigentum\
       gehören nach wie vor 3XCEED',
  para2:
    '3XCEED setzt sich dafür ein, dass die App so nützlich und effizient wie möglich ist. Aus diesem \
  Grund behalten wir uns das Recht vor, jederzeit und aus beliebigem Grund Änderungen an der App vorzunehmen \
  oder ihre Dienste in Rechnung zu stellen. Wir werden Ihnen niemals die App oder ihre Dienste in Rechnung \
  stellen, ohne Ihnen genau klar zu machen, wofür Sie genau bezahlen',
  para3:
    'Die Yush-App speichert und verarbeitet persönliche Daten, die Sie uns zur Verfügung gestellt haben, \
  um unseren Service anzubieten. Es liegt in Ihrer Verantwortung, Ihr Telefon und den Zugriff auf die App \
  sicher zu halten. Wir empfehlen Ihnen daher, Ihr Telefon nicht auszubrechen oder als Root zu verwenden. \
  Dies ist der Prozess der Beseitigung von Software-Einschränkungen und Beschränkungen, die durch das \
  offizielle Betriebssystem Ihres Geräts auferlegt werden. Es könnte Ihr Telefon anfällig für Malware/Viren/Bösartige\
   Programme machen, die Sicherheitsfunktionen Ihres Telefons beeinträchtigen und es könnte bedeuten, dass die \
   Yush-App nicht richtig oder überhaupt nicht funktioniert,',
  para4: 'Die Anwendung nutzt Dienste von Drittanbietern, die ihre eigenen Allgemeinen Geschäftsbedingungen erklären.',
  para5: 'Link zu den Allgemeinen Geschäftsbedingungen von Drittanbietern, die von der App benutzt werden',
  para6:
    'Sie sollten sich bewusst sein, dass es bestimmte Dinge gibt, für die 3XCEED keine Verantwortung übernimmt. \
  Bestimmte Funktionen der App setzen voraus, dass die App über eine aktive Internetverbindung verfügt. Die \
  Verbindung kann über Wi-Fi erfolgen oder von Ihrem Mobilfunkanbieter bereitgestellt werden. 3XCEED kann jedoch \
  keine Verantwortung dafür übernehmen, dass die App nicht mit voller Funktionalität funktioniert, wenn Sie keinen \
  Zugang zu Wi-Fi haben und keine Daten mehr zur Verfügung stehen.',
  para7:
    'Wenn Sie die Anwendung außerhalb eines Gebietes mit Wi-Fi verwenden, sollten Sie daran denken, dass Ihre \
  Bedingungen der Vereinbarung mit Ihrem Mobilfunknetzbetreiber weiterhin gelten. Infolgedessen können Ihnen von \
  Ihrem Mobilfunkanbieter die Datenkosten für die Dauer der Verbindung während des Zugriffs auf die App oder andere \
  Gebühren von Dritten in Rechnung gestellt werden. Mit der Nutzung der App übernehmen Sie die Verantwortung für \
  alle derartigen Gebühren, einschließlich der Datenroaming-Gebühren, wenn Sie die App außerhalb Ihres Heimatgebiets \
  (d. h. einer Region oder eines Landes) nutzen, ohne das Datenroaming abzuschalten. Wenn Sie nicht der Rechnungszahler \
  für das Gerät sind, auf dem Sie die App nutzen, gehen wir davon aus, dass Sie vom Rechnungszahler die Erlaubnis zur \
  Nutzung der App erhalten haben.',
  para8:
    'Ebenso kann 3XCEED nicht immer die Verantwortung für die Art und Weise übernehmen, wie Sie die App verwenden, \
  d. h. Sie müssen sicherstellen, dass Ihr Gerät geladen bleibt - wenn der Akku leer ist und Sie es nicht einschalten \
  können, um den Service in Anspruch zu nehmen, kann 3XCEED keine Verantwortung übernehmen.',
  para9:
    'In Bezug auf die Verantwortung von 3XCEED für Ihre Nutzung der App ist zu beachten, dass wir uns zwar bemühen, \
  sicherzustellen, dass die App jederzeit aktualisiert und korrekt ist, dass wir uns jedoch darauf verlassen, dass Dritte \
  uns Informationen zur Verfügung stellen, damit wir Ihnen diese zur Verfügung stellen können. 3XCEED übernimmt keine \
  Haftung für Verluste, direkt oder indirekt, die Ihnen entstehen, wenn Sie sich vollständig auf diese Funktionalität \
  der App verlassen.',
  para10:
    'Irgendwann möchten wir vielleicht die App aktualisieren. Die App ist derzeit auf Android & iOS verfügbar - \
  die Anforderungen für beide Systeme (und für alle zusätzlichen Systeme, auf die wir die Verfügbarkeit der App erweitern \
    möchten) können sich ändern, und Sie müssen die Aktualisierungen herunterladen, wenn Sie die App weiterhin nutzen \
    möchten. 3XCEED verspricht nicht, die App immer so zu aktualisieren, dass sie für Sie relevant ist und/oder mit der \
    Android- & iOS-Version funktioniert, die Sie auf Ihrem Gerät installiert haben. Sie versprechen jedoch, Aktualisierungen \
    der Anwendung immer zu akzeptieren, wenn sie Ihnen angeboten werden. Es kann auch sein, dass wir die Bereitstellung der \
    Anwendung einstellen möchten und die Nutzung der Anwendung jederzeit beenden können, ohne Sie davon in Kenntnis zu setzen. \
    Sofern wir Ihnen nichts anderes mitteilen, enden bei einer Kündigung (a) die Ihnen in diesen Bedingungen gewährten Rechte \
    und Lizenzen; (b) müssen Sie die Nutzung der Anwendung einstellen und (falls erforderlich) löschen Sie es von Ihrem Gerät.',
  strong1: 'Änderungen an diesen Nutzungsbedingungen',
  para11:
    'Wir können unsere Allgemeinen Geschäftsbedingungen von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, \
  diese Seite regelmäßig auf etwaige Änderungen zu überprüfen. Wir werden Sie über alle Änderungen informieren, indem wir \
  die neuen Allgemeinen Geschäftsbedingungen auf dieser Seite veröffentlichen.',
  para12: 'Diese Bedingungen gelten ab 2020-09-27',
  strong2: 'Kontaktieren Sie uns',
  para13:
    'Wenn Sie Fragen oder Anregungen zu unseren Allgemeinen Geschäftsbedingungen haben, zögern Sie nicht, uns unter \
  info@yush.app zu kontaktieren.',
  para14: 'Diese Seite mit den Allgemeinen Geschäftsbedingungen wurde erstellt von',
};

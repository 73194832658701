export default {
  main: {
    title_stailey: 'Willkommen in der Rezeptdatenbank von "Homemade food by stailey"',
    title_phil: 'Willkommen in der Rezeptdatenbank von "Phils family food"',
    title: 'Willkommen in der Rezeptdatenbank für Yush!',
    name: 'Name:',
    description: 'Beschreibung:',
    type: 'Typ:',
    images: 'Bilder:',
    ingredients: 'Zutaten:',
    instructions: 'Zubereitung:',
    serves: 'Portionen:',
    prep: 'Zubereitungszeit (in Min.):',
    total: 'Gesamtzeit (in Min.):',
    reviews: 'Bewertungen:',
    rating: 'Rating:',
    author: 'Autor:',
    types: {
      breakfast: 'Frühstück',
      salad: 'Salat',
      dessert: 'Nachtisch',
      drink: 'Getränke',
      appetizers: 'Vorspeisen',
      dinner: 'Abendessen',
      lunch: 'Mittagessen',
      snacks: 'Snacks',
    },
    disclaimer:
      'Ich stimme zu, dass das eingegebene Rezept vor mir stammt und ich über die Bildrechte der angehängten Bilder verfüge. Des Weiteren stimme ich zu, dass 3XCEED das Rezept inklusive Bilder auf deren Webseite yush.app, in ihrer App Yush! sowie auf  dessen Social Media Profilen wiederverwenden darf.',
    message: 'Vielen Dank! Das Rezept wurde erfolgreich gespeichert',
    extra: 'Zusätzliche Info',
    vegan: 'Vegan',
    veg: 'Vegetarisch',
    fish: 'Fisch',
    meat: 'Fleisch',
  },
};

export default {
  main: {
    slogan: 'So funktioniert´s',
    title: 'So funktioniert´s',
  },
  item1: {
    header: 'Rezepte auswählen',
    description: 'Egal ob vegetarisch, vegan, Frühstück oder Dessert, hier findest Du die passendenden Rezepte',
    footer: 'Sparen leicht gemacht',
  },
  item2: {
    header: 'Wochenplan erstellen',
    description: 'Stelle deinen individuellen Wochenplan zusammen',
    footer: 'Tschüss Spontankauf! Hallo Planung',
  },
  item3: {
    header: 'Angebote vergleichen',
    description: 'Wähle aus einer Vielzahl an Angeboten und spare Woche für Woche',
    footer: 'Endlich Zeit für die wichtigen Dinge im Leben',
  },
  item4: {
    header: 'Clever einkaufen',
    description:
      'Übernehme die für deinen Wochenplan benötigten Zutaten mit einem Klick in deine persönliche Einkaufsliste und teile sie mit der Familie und Freunden',
    footer: 'Schluss mit langen Überlegungen',
  },
};

export default {
  main: {
    title_stailey: 'Welcome to recipe database by "Homemade food by stailey"',
    title_phil: 'Welcome to recipe database by "Phils family food"',
    title: 'Welcome to recipe database for Yush!',
    name: 'Name:',
    description: 'Description:',
    type: 'Type:',
    images: 'Images:',
    ingredients: 'Ingredients:',
    instructions: 'Instructions:',
    serves: 'Serves:',
    prep: 'Preparation time (in mins):',
    total: 'Total time (in mins):',
    reviews: 'Reviews:',
    rating: 'Rating:',
    author: 'Author:',
    types: {
      breakfast: 'Breakfast',
      salad: 'Salad',
      dessert: 'Desserts',
      drink: 'Drinks',
      appetizers: 'Appetizers',
      dinner: 'Dinner',
      lunch: 'Lunch',
      snacks: 'Snacks',
    },
    disclaimer:
      'I agree that the recipe entered is mine and that I have the image rights to the attached images. Furthermore, I agree that 3XCEED may reuse the recipe including images on their website yush.app, in their app Yush! as well as on its social media profiles.',
    message: 'Thank you! The recipe has been saved successfully',
    extra: 'Additional Info',
    vegan: 'Vegan',
    veg: 'Vegetarian',
    fish: 'Fish',
    meat: 'Meat',
  },
};

export default {
  main: {
    title: 'Ultimate features in one application',
    slogan: 'Features',
  },
  item1: {
    header: 'Offers',
    description: 'Save money through offers from various supermarkets',
  },
  item2: {
    header: 'Shopping Lists',
    description: 'Manage and share your personalized shopping lists online and offline',
  },
  item3: {
    header: 'Multilingual',
    description: 'Available in German and English',
  },
  item4: {
    header: 'Recalls',
    description: 'Get information about current food recalls',
  },
  item5: {
    header: 'Recipes',
    description: 'Choose your favourite dishes from numerous recipes',
  },
  item6: {
    header: 'Transparency',
    description: 'Know more about your product with complete transparency',
  },
};

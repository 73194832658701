export default {
  main: {
    id: 'Product ID',
  },
  item1: {
    header: 'Production',
    description: 'Choose your favourite products from a wide range of offers',
  },
  item2: {
    header: 'Manufacturing',
    description: 'Put together your personal shopping list and check the approximate amount of money you will spend',
  },
  item3: {
    header: 'Logistics',
    description: 'We help you to sort your shopping list so that you can do your shopping as fast as possible',
  },
  item4: {
    header: 'Supermarkets',
    description: 'We recommend healthy recipes based on your shopping',
  },
  item5: {
    header: 'Consumption',
    description: 'We recommend healthy recipes based on your shopping',
  },
};

export default {
  main: {
    title: 'Ultimative Funktionen in einer Anwendung',
    slogan: 'Gesund, nachhaltig, zeit- und geldsparend',
  },
  item1: {
    header: 'Gesund',
    description:
      'Wir bieten Dir gesunde Rezepte und Du legst fest, welche Du davon in deinen Wochenplan übernehmen möchtest. Gesunde Ernährung leicht gemacht. Und fehlt eines deiner Lieblingsgerichte, dann füge es in wenigen Schritten hinzu.',
  },
  item2: {
    header: 'Nachhaltig',
    description:
      'Wir sind bereits Teil der Initiative „ZU GUT FÜR DIE TONNE“. Trage auch Du aktiv zur Reduzierung von Lebensmittelverschwendungen bei.',
  },
  item3: {
    header: 'Zeitsparend',
    description:
      'In wenigen Minuten stellst Du aus zahlreichen Rezepten deinen persönlichen Wochenplan zusammen. Die dafür benötigten Zutaten übernimmst Du direkt in deine Einkaufsliste. Schluss mit langen Überlegungen, was Du essen möchtest.',
  },
  item4: {
    header: 'Geldsparend',
    description:
      'Mit der richtigen Planung kaufst Du nur die Lebensmittel ein, die Du auch tatsächlich benötigst. Zudem kannst Du vor deinem Einkauf Angebote diverser Händler vergleichen. Tschüss Spontankauf! Hallo Planung!',
  },
};
